import React from 'react';
import Helmet from 'react-helmet';
import {
    Container,
  } from 'reactstrap';
import SiteNavbar from './navbar';


export default function Layout({children}) {
    return <div>
    
        <Helmet>
            <title>Katie S. Dunne</title>
        </Helmet>

        <div className="mb-3">
            <SiteNavbar/>
        </div>

        <Container>
    
            {children}
    
            <hr/>
    
            <footer className="text-right">
                &copy; {(new Date()).getFullYear()} Katie S. Dunne
            </footer>
    
        </Container>
    </div>;
}
