import React, { useState } from 'react';
import { Link } from "gatsby";
import {
    Container,
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
} from 'reactstrap';

export default function SiteNavbar(props) {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <Navbar color="light" light expand="md">
            <Container>
                <NavbarBrand tag={Link} to="/">
                    Katie S. Dunne
                </NavbarBrand>
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="mr-auto" navbar>
                        <NavItem>
                            <NavLink tag={Link} to="/cv">Curriculum Vitae</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="https://github.com/ksdunne">GitHub</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="https://r.ksdunne.com">R Code</NavLink>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Container>
        </Navbar>
    );
}
